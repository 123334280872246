<template>
  <div class="verification" :class="{'verification-center':showPage == 2,'verification-current':showPage == 1}">
    <!-- 核销 -->
    <div class="page-one" v-show="showPage == 1">
      <div class="verification-title" @click="clear">请输入核销信息</div>
      <div class="verification-form">
        <div class="input-list account">
          <input class="input" type="text" placeholder="请输入核销码" v-model="codeValue">
          <div class="verification-code" @click="scan">
            <div class="code-img"></div>
            <div class="code-text">扫码核销</div>
          </div>
        </div>
        <div class="submit" :class="{'btn-disabled' : codeValue.trim() == ''}" @click="toPage(3)">确认</div>
      </div>
    </div>
    <!-- 个人中心 -->
    <div class="page-two" v-show="showPage == 2">
      <div class="name">{{ nickName }}</div>
      <div class="account">账号：{{ email }}</div>
      <div class="login-out">
        <div class="icon"></div>
        <div class="text" @click="loginOut">退出登录</div>
      </div>
    </div>
    <!-- 核销信息 -->
    <div class="page-three" v-show="showPage == 3">
      <div class="message-info">
        <div class="title">订单信息</div>
        <div class="list-box">
          <div class="info-list">
            <div class="list-name">核销编码</div>
            <div class="list-value">{{ infoData.code}}</div>
          </div>
          <div class="info-list">
            <div class="list-name">商品名称</div>
            <div class="list-value">{{ infoData.skuName }}</div>
          </div>
          <div class="info-list">
            <div class="list-name">订单时间</div>
            <div class="list-value">{{ infoData.outTradeDate }}</div>
          </div>
          <div class="info-list">
            <div class="list-name">发码时间</div>
            <div class="list-value">{{ infoData.sendDate }}</div>
          </div>
          <div class="info-list">
            <div class="list-name">使用范围</div>
            <div class="list-value">{{ orderType }}</div>
          </div>
        </div>
      </div>
      <div class="message-tip">请认真核对当天日期与门票使用范围一致！</div>
      <div class="verification-submit" @click="submitOrder">确认核销</div>
      <div class="back-btn" @click="toPage(1)">返回</div>
    </div>
    <!-- 核销成功 -->
    <div class="page-success" v-show="showPage == 4">
      <div class="success-img">
        <img src="https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/verification-success.png" alt="">
      </div>
      <div class="success-text">核销成功</div>                         
      <div class="back-btn" @click="toPage(1)">返回</div>
    </div>
    <!-- 底部切换 -->
    <div class="fixed-bottom" v-show="showPage == 1 || showPage == 2">
      <div class="fixed-bottom-btn" :class="{'check':showPage == 1}" @click="toPage(1)">
        <div class="text">门票核销</div>
        <div class="line"></div>
      </div>
      <div class="line-bottom"></div>
      <div class="fixed-bottom-btn" :class="{'check':showPage == 2}" @click="toPage(2)">
        <div class="text">个人中心</div>
        <div class="line"></div>
      </div>
    </div>
    <!-- 核销loading -->
    <div class="loading-layer" v-if="showLoading">
      <div class="layer-con">
        <van-loading size="30px" text-color="#ffffff" type="spinner" vertical>请耐心等待核销结果</van-loading>
      </div>
    </div>
    <!-- 请求loading -->
    <div class="loading-layer loading-layer-page" v-if="pageLoading">
      <div class="layer-con">
        <van-loading size="30px" type="spinner"></van-loading>
      </div>
    </div>
  </div>
</template>
<script>
document.title ='连物界'
import { showToast,showConfirmDialog } from 'vant';
import { defineComponent } from 'vue'
import { getJsSDKConfigApi} from "../../api/wx";
import { getTicketInfo, submitTicketOrder,loginOut } from "@/api/yijifen";
import wx from 'weixin-js-sdk'; // 引入wxjs
export default defineComponent({
    data() {
      return {
        codeValue:'',
        nickName:'',
        email:'',
        orderType:'仅周六、日使用',
        showPage:1,
        infoData:{
          code:'',
          skuName:'',
          outTradeDate:'',
          sendDate:''
        },
        showLoading:false,
        pageLoading:false,
        getScan:false
      }
    },
		created() {
			
      if(!localStorage.getItem('ticketToken')){
        this.$router.replace({ name:'yijifenLogin'})
      }
      this.nickName = localStorage.getItem('nickName')
      this.email = localStorage.getItem('email')
		},
    mounted(){
      // const isWx = this.isWinXinBrowser();
			// if (isWx) {
      //   this.pageLoading = true
			// 	this.setWxShareInfo();
			// }
    },
    methods: {
      isWinXinBrowser() {
          //是否是微信浏览器
          if (/(micromessenger)/i.test(navigator.userAgent)) {
              // //是否电脑微信或者微信开发者工具
              // if (/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)) {
              //     alert('电脑微信或者微信开发者工具')
              // } else {
              //     //手机微信打开的浏览器
              //     alert('手机微信')
              // }
              return true;
          } else {
              // alert('其他浏览器')
              return false;
          }
      },
      setWxShareInfo() {
        let that = this
        const isWx = this.isWinXinBrowser();
        if (isWx) {
          this.pageLoading = true
          getJsSDKConfigApi({
            url: window.location.origin + '/yijifen/verification'
          }).then((res) => {
            this.pageLoading = false
            this.getScan = true
            const config = res;
            wx.config({
              appId: config.appId, // 必填，公众号的唯一标识
              timestamp: config.timestamp, // 必填，生成签名的时间戳
              nonceStr: config.noncestr, // 必填，生成签名的随机串
              signature: config.signature,// 必填，签名
              jsApiList: ['scanQRCode',],
              // debug:true
            });
            wx.ready(function(){
              that.scan()
            });
            wx.error(function (res) {
              console.log(res)
            });
          });
        }
        
      },
      scan(){
        if(this.getScan){
          let that = this
          wx.scanQRCode({
            needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
            scanType: ["qrCode","barCode"], // 可以指定扫二维码还是一维码，默认二者都有
            success: function (res) {
              // showToast(JSON.stringify(res))
              setTimeout(()=>{
                that.codeValue = res.resultStr.split(',')[1]
              },500)
            },
            error:function(res){
              showToast(JSON.stringify(res));
            }
          });
        }else{
          this.setWxShareInfo()
        }
      },
      toPage(e){
        if(this.showPage = 1 && e == 3){
          if(this.codeValue.trim() == ''){
            return
          }
        }
        if(e == 3){
          this.getInfo()
        }else{
          this.showPage = e
        }
      },
      getInfo(){
        this.pageLoading = true
        this.codeValue = this.codeValue.trim()
        getTicketInfo({
          ticketCode: this.codeValue
        }).then((res) => {
          this.pageLoading = false
          if(res.code == 200 && res.result){
            this.infoData = res.result
            this.showPage = 3
            if(res.result.skuName.indexOf('平日') > -1){
              this.orderType = '仅周一至周五使用'
            }
          }else{
            showToast(res.msg);
          }
        })
      },
      submitOrder(){
        this.showLoading = true
        submitTicketOrder({
          ticketCode: this.codeValue
        }).then((res) => {
          this.showLoading = false
          if(res.code == 200){
            if(res.result.code == '00'){
              this.showPage = 4
            }else{
              showToast(res.result.msg);
            }
          }else{
            showToast(res.msg);
          }
        })
      },
      clear(){
        localStorage.clear()
      },
      loginOut(){
        let that = this
        showConfirmDialog({
          title: '提示',
          message:'是否要退出登录？',
        })
        .then(() => {
          loginOut().then((res)=>{
            if(res.code == 200){
              localStorage.clear()
              that.$router.replace({ name:'yijifenLogin'})
            }else{
              showToast(res.msg);
            }
          })
        })
        .catch(() => {
          // on cancel
        });
      } 
    },
})

</script>
<style lang="scss" scoped>
  .verification{
    width: 100%;
    height: 100vh;
    background: #000;
  //  overflow: hidden;
  }
  .verification-center{
    background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/center-bg.png') no-repeat;
    background-size: cover;
  }
  .verification-current{
    background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/login-bg.png') no-repeat;
    background-size: cover;
  }
  .verification-title{
    text-align: left;
    font-size: 28px;
    color: #A5FFFD;
    padding: 170px 0 0 30px;
    box-sizing: border-box;
  }
  .verification-form{
    width: 330px;
    height: 196px;
    background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/verification-form.png') no-repeat;
    background-size: 100% 100%;
    margin: 15px auto 0 auto;
    padding: 35px 0 0 16px;
    box-sizing: border-box;
    .input-list{
      width: 284px;
      height: 42px;
      border-radius: 6px;
      background: #182C33;
      margin-bottom: 20px;
      padding: 0 0 0 14px;
      box-sizing: border-box;
      display: flex;
      align-content: center;
      justify-content: space-between;
      .input{
        flex: 1;
        height: 42px;
        color: #A5FFFD;
        font-size: 14px;
        background: transparent;
        border: none;
        outline: none;
      }
      .verification-code{
        width: 74px;
        height: 42px;
        border-radius: 0 6px 6px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #A5FFFD;
        margin-left: 14px;
      }
      .code-img{
        width: 18px;
        height: 18px;
        background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/verification-code.png') no-repeat;
        background-size: contain;
      }
      .code-text{
        color: #111111;
        font-size: 8px;
        margin-top: 4px;
      }
    }
    .submit{
      width: 284px;
      height: 46px;
      border-radius: 6px;
      background: #A5FFFD;
      color: #000;
      font-size: 14px;
      text-align: center;
      line-height: 46px;
      margin-top: 34px;
    }
    .btn-disabled{
      background: #568D93;
      color: #3C6367;
    }
  }
  .page-two{
    .name{
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #A5FFFD;
      padding-top: 260px;
    }
    .account{
      margin-top: 10px;
      color: #6E8992;
      font-size: 14px;
    }
    .login-out{
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 150px;
      width: 330px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #273D44;
      border-radius: 8px;
      color: #6E8992;
      font-size: 14px;
      .icon{
        width: 18px;
        height: 18px;
        margin-right: 8px;
        background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/login-out.png') no-repeat;
        background-size: contain;
      }
    }
  }
  .page-three{
    .message-info{
      width: 100%;
      padding-bottom: 32px;
      // height: 300px;
      background: url('https://toyspace-domestic-prod.oss-cn-beijing.aliyuncs.com/public/mini/ticket/info-bg.png') no-repeat;
      background-size: 100% 100%;
    }
    .title{
      padding: 76px 0 0 60px;
      color: #333;
      font-size: 16px;
      text-align: left;
      box-sizing: border-box;
    }
    .list-box{
      padding: 46px 60px 0 60px;
      box-sizing: border-box;
      .info-list{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        padding-bottom: 18px;
        .list-name{
          color: #666;
        }
        .list-value{
          flex: none;
          width: 174px;
          text-align: right;
          color: #000;
        }
      }
      .info-list:last-child{
        padding-bottom: 0;
      }
    }
    .message-tip{
      color: #A5FFFD;
      font-size: 12px;
      padding-top: 20px;
    }
    .verification-submit{
      width: 284px;
      height: 46px;
      border-radius: 6px;
      background: #A5FFFD;
      text-align: center;
      line-height: 46px;
      color: #000;
      font-size: 14px;
      margin: 116px auto 0 auto;
      font-weight: 500;
    }
    .back-btn{
      width: 284px;
      height: 46px;
      border-radius: 6px;
      border: 1px solid #A5FFFD;
      text-align: center;
      line-height: 46px;
      color: #A5FFFD;
      font-size: 14px;
      margin: 26px auto 0 auto;
    }
  }
  .fixed-bottom{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    // height: 80px;
    background: rgba(24, 44, 51, 0.95);
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: 20px 0;
    box-sizing: border-box;
    .line-bottom{
      width: 2px;
      height: 20px;
      margin: 0 65px;
      background: #6E8992;
    }
    
    .fixed-bottom-btn{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #6E8992;
      .line{
        width: 30px;
        height: 5px;
        border-radius: 6px;
        background: transparent;
        margin-top: 6px;
      }
    }
    .check{
      color: #A5FFFD;
      .line{
        background: #A5FFFD;
      }
    }
  }
  .page-success{
    .success-img{
      width: 200px;
      padding-top:122px ;
      margin: 0 auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .success-text{
      width: 100%;
      text-align: center;
      font-size: 16px;
      color: #A5FFFD;
    }
    .back-btn{
      width: 284px;
      height: 46px;
      border-radius: 6px;
      border: 1px solid #A5FFFD;
      text-align: center;
      line-height: 46px;
      color: #A5FFFD;
      font-size: 14px;
      margin: 150px auto 0 auto;
    }
  }
  .loading-layer{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    z-index: 99;
    background: rgba(0, 0, 0, 0.60);
    display: flex;
    justify-content: center;
    padding-top: 200px;
    box-sizing: border-box;
    .layer-con{
      width: 162px;
      height: 94px;
      padding: 26px 0 14px 0;
      border-radius: 8px;
      box-sizing: border-box;
      background: #181818;
    }
  }
  .loading-layer-page{
    padding-top: 0;
    align-items: center;
    .layer-con{
      background: transparent;
    }
  }
</style>
